var App = {
	initilize: function() {
		// Dölj modal info+success efter ... en stund
		$('.alert-success, .alert-info').delay(3000).hide('fast');
		
		// Modal för bildvisning
		$('#imageViewBox').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget);
			
			var url = $(this).find('#imageViewBoxImg').data('baseurl') + '/' + button.data('imageurl');
			$(this).find('#imageViewBoxImg').attr('src', url);
			
			$(this).find('#imagePlaceholderNames').html(button.data('imagenames'));
			$(this).find('#imagePlaceholderPlace').html(button.data('imageplace'));
			$(this).find('#imagePlaceholderYear').html(button.data('imageyear'));
			$(this).find('#imagePlaceholderDescription').html(button.data('imagedescription'));
			$(this).find('#imagePlaceholderAuthor').html(button.data('imageauthor'));
		});
		
		// Open modal if validation fails
		$('.modal[data-openthismodal="true"]').modal('show');
	}
	
};

$(function() {
	App.initilize();
});

